import React from 'react';
import { Row, TableOptions, useReactTable } from '@tanstack/react-table';
import TreeViewItem from './types/tree-view-item.type';

import './styles/main.scss';

interface Props<T extends TreeViewItem> {
    className?: string;
    options: TableOptions<T>;
    renderItem: (row: Row<any>) => React.JSX.Element;
}

/**
 * This component is responsible for rendering the tree view list.
 */
const TreeViewList = <T extends TreeViewItem>({ className, options, renderItem }: Props<T>) => {
    const table = useReactTable(options);
    const rows = table.getRowModel().rows;

    return (
        <ul className={`tree-view-list ${className}`}>
            {rows.map((row) => (
                <li key={row.id}>{renderItem(row)}</li>
            ))}
        </ul>
    );
};

export default TreeViewList;
